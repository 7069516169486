import React, { useEffect } from 'react';
import { RootState } from '../../store';
import { menuController } from '@ionic/core/components';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { hideSlideOutMenu } from '../../services/MenuSlice';
import { isDev, openHelp } from '../../util';
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonMenu,
  IonSpinner
} from '@ionic/react';
import Illustration from '../../../components/Illustrations/Illustration';
import HomeIcon from '../../../assets/icons/home.svg';
import InventoryIcon from '../../../assets/icons/inventory.svg';
import ContactsIcon from '../../../assets/icons/contacts.svg';
import DocumentsIcon from '../../../assets/icons/documents.svg';
import AvidWarrantyIcon from '../../../assets/icons/aw-warranty.svg';
import './SideMenu.scss';
import { fetchZendeskToken } from 'core/services/UserActions';
import { ZENDESK_HELP_URL } from 'core/constants';
import HomeSwitcher from 'components/home-switcher/HomeSwitcher';

interface Page {
  title: string;
  path: string;
  icon: string;
  hidden: boolean;
}

const SideMenu: React.FC = ({ children }) => {
  const { platformType, version } = useSelector(
    (state: RootState) => state.platform
  );
  const { isSideMenuOpen } = useSelector((state: RootState) => state.menu);
  const { zendesk_token_url } = useSelector((state: RootState) => state.user);
  const { homes, builderLogo, warrantyURL } = useSelector(
    (state: RootState) => state.home
  );

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const pages: Page[] = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: String(HomeIcon),
      hidden: false
    },
    {
      title: 'Contacts',
      path: '/contacts',
      icon: String(ContactsIcon),
      hidden: false
    },
    {
      title: 'Inventory',
      path: '/inventory',
      icon: String(InventoryIcon),
      hidden: false
    },
    {
      title: 'Documents',
      path: '/documents',
      icon: String(DocumentsIcon),
      hidden: false
    },
    {
      ...(warrantyURL
        ? ({
            title: 'Warranty',
            path: warrantyURL,
            icon: String(AvidWarrantyIcon),
            hidden: false
          } as Page)
        : ({} as Page))
    }
  ];

  if (isSideMenuOpen) {
    openMenu().then(() => {});
  } else {
    closeMenu().then(() => {});
  }

  async function openMenu() {
    await menuController.open();
  }

  async function closeMenu() {
    await menuController.close();
  }

  const closeMenuAndShowHelp = async () => {
    dispatch(hideSlideOutMenu());
    await dispatch(fetchZendeskToken(ZENDESK_HELP_URL));
    if (!!zendesk_token_url) {
      openHelp(zendesk_token_url, platformType);
    }
  };

  const navigateTo = (route: string) => {
    if (route.startsWith('http://') || route.startsWith('https://')) {
      window.open(route, '_blank');
    } else {
      history.push(route);
    }
    dispatch(hideSlideOutMenu());
  };

  const menuDidClose = () => {
    dispatch(hideSlideOutMenu());
  };

  return (
    <IonMenu
      side="start"
      type="push"
      contentId="main-content"
      className="main-menu"
      onIonDidClose={() => menuDidClose()}
    >
      <IonHeader scroll-y="false">
        {builderLogo ? (
          <Illustration image={builderLogo} />
        ) : (
          <IonSpinner name="crescent" />
        )}
      </IonHeader>
      <IonContent>
        {!!homes && homes.length > 1 && <HomeSwitcher />}
        <IonList
          className={`${!!homes && homes.length > 1 && 'main-menu-items'}`}
        >
          {pages.map((page, i) => {
            return (
              <IonItem
                key={`menu-item-${i}`}
                color={location.pathname.includes(page.path) ? 'grey' : 'dark'}
                onClick={() => navigateTo(page.path)}
                hidden={page.hidden}
              >
                <IonIcon icon={page.icon} slot="start" />
                {page.title}
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
      <div id="side-menu-footer">
        <IonList>
          <IonItem color="dark" onClick={closeMenuAndShowHelp}>
            Help
          </IonItem>
          <IonItem color="dark" />
          <IonItem color="dark" className="no-bold">
            {version} {isDev() && <div className="dev-tag">DEV</div>}
          </IonItem>
        </IonList>
      </div>
    </IonMenu>
  );
};

export default SideMenu;
